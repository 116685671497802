import Vue from 'vue'
import Router from 'vue-router'

// Components
import MainLayout from '@/components/MainLayout'
import QrGenerator from '@/components/tools/QrGenerator'

Vue.use(Router)

export default new Router ({
  mode: "history",
  routes:[
    {
      name: "Home",
      path: "/",
      component: MainLayout
    },
    {
      name: "QrGenerator",
      path: "/qrgenerator",
      component: QrGenerator
    }
  ]
})

