<template>
  <b-navbar toggleable="lg" type="light" variant="transparent">
    <b-navbar-brand :to="{name: 'Home'}">Utilities</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <!-- <b-nav-item href="#">QR Generator</b-nav-item>
        <b-nav-item><router-link to="QrGenerator"> QR code</router-link></b-nav-item> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>

export default {
  name: "NavMenu"
}
</script>