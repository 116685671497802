<template>
  <b-container fluid id="mainLayout">
    <nav-menu></nav-menu>
    <div id="content" class="p-2">
      <qr-generator></qr-generator>
    </div>
    <nav-footer></nav-footer>
  </b-container>
</template>

<script>

import NavMenu from '@/components/NavMenu'
import NavFooter from '@/components/NavFooter'
import QrGenerator from '@/components/tools/QrGenerator'

export default {
  name: "MainLayout",
  components: {NavMenu, NavFooter, QrGenerator},
  methods: {

  }
}
</script>

<style>
  #mainLayout {
    padding: 0;
  }
</style>
