<template>
  <footer id="navfooter">
    <b-navbar variant="faded" type="light" fixed="bottom">
      <b-navbar-nav class="mr-auto">
        <b-navbar-brand href="https://hus-tec.ch">HusTec</b-navbar-brand>
      </b-navbar-nav>
      <b-navbar-nav class="mx-auto">
        <b-nav-item href="#" disabled>Copyright ® HusTec 2022</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <h5><b-nav-item href="mailto:info@hus-tec.ch">Contact</b-nav-item></h5>
      </b-navbar-nav>
    </b-navbar>
  </footer>
</template>

<script>
export default {
  name: "NavFooter",
  data () {
    return {

    }
  }
}
</script>