<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card
          title="QR Creator"
          style="max-width: 200rem;"
          class="mb-2"
          id="qrgenerator"
        >
          <b-card-text>
            Generate a QR Code based on your text
          </b-card-text>
          <b-card-text>
            <b-form-input v-model="text" placeholder="Text to generate"></b-form-input>
          </b-card-text>

          <b-button variant="primary" v-on:click="QrtoCanvas">Generate</b-button>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-card-body>
            <canvas id="preview"></canvas>
          </b-card-body>
        </b-card>
      </b-col>  
    </b-row>
  </b-container>
</template>

<script>

import QrCode from 'qrcode'

export default {
  name: "QrGenerator",
  data() {
    return {
      text: ""
    }
  },
  methods: {
    QrtoCanvas: function () {
      var canvas = document.getElementById("preview")
      QrCode.toCanvas(canvas, this.text)
    }
  }
}
</script>